import { useEffect } from 'react';
import { createPortal } from 'react-dom';

import closeImg from '@css-theme/icons/close.svg';

import styles from './popup.module.styl';

type IPopupProps = {
  closeIcon?: boolean
  onClose?: () => void
  children?: React.ReactNode
}

const PopupNode:React.FC<IPopupProps> = ({ onClose, closeIcon = true, children }) => {
  const escapeHandler = (event:KeyboardEvent) => {
    if (event.which === 27) {
      onClose?.();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', escapeHandler);
    return () => {
      document.removeEventListener('keyup', escapeHandler);
    };
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.back} onClick={onClose}/>
      <div className={styles.content}>
        { children }
      </div>
      { closeIcon &&
        <div className={styles.close} onClick={onClose}>
          <img src={closeImg}/>
        </div>
      }
    </div>
  );
};

export const Popup:React.FC<IPopupProps> = props => createPortal(
  <PopupNode {...props}/>,
  document.body
);