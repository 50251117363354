import { Delimeter } from 'shared/components/delimeter';
import { Paragraph } from 'shared/components/paragraph';

import styles from './controls.module.styl';

export const InputHint:React.FC<{ hint?:string }> = ({ hint }) =>
  hint ?
    <>
      <Delimeter size="xxs"/>
      <Paragraph className={styles.hint} size="xs" grey>{ hint }</Paragraph>
    </> :
    null;