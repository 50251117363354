import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { Delimeter } from '~/shared/components/delimeter';
import { Paragraph } from '~/shared/components/paragraph';

import styles from './success-layout.module.styl';

type SuccessLayoutProps = {
  title: React.ReactNode
  text?: React.ReactNode
  backText?: string
  backUrl?: string
  buttons?: React.ReactNode
  clear?: boolean
}

export const SuccessLayout:React.FC<SuccessLayoutProps> = ({ title, text, backText, backUrl, buttons, clear }) => {
  const navigate = useNavigate();

  const back = () => { navigate(backUrl || '../') };

  return (
    <div className={classNames(styles.root, { [styles.clear]: clear })}>
      <div className={styles.content}>
        <Paragraph size="2xl">{ title }</Paragraph>
        {text && <Paragraph size="xl">{ text }</Paragraph>}
        {buttons &&
          <>
            <Delimeter/>
            <Delimeter size="xs"/>
            { buttons }
          </>
        }
        {backText &&
          <>
            <Delimeter size="l"/>
            <Delimeter size="xs"/>
            <a onClick={back}>{ backText }</a>
          </>
        }
      </div>
    </div>
  );
};