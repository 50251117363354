import { SuccessLayout } from '~/shared/components/success-layout';

const EmployerSecurityComplete:React.FC = () => {
  return (
    <SuccessLayout
      title={<>Анкета принята!<br/>Ты на 10 шагов ближе к найму!</>}
      text="Менеджер по персоналу свяжется с тобой в течение дня и назначит собеседование."
    />
  );
};

export default EmployerSecurityComplete;