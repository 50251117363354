import { SuccessLayout } from '~/shared/components/success-layout';

const EmployerUploadComplete:React.FC = () => {
  return (
    <SuccessLayout
      title={<>Документы приняты!<br/>Добро пожаловать в команду!</>}
      text="Менеджер по персоналу свяжется с тобой в течение дня и назначит день подписания документов о приёме на работу."
    />
  );
};

export default EmployerUploadComplete;