import { FileError } from 'react-dropzone';

import { ACCEPTED_FILE_EXTENSIONS, MAX_UPLOAD_FILE_SIZE } from './constants';
import { IFileRejection, IFileRejections } from './d';

export const rejections:IFileRejections = {
  'file-too-large': {
    title: 'Слишком большой файл',
    desc: `Загрузите файл меньше ${MAX_UPLOAD_FILE_SIZE} Мб.`
  },
  'file-invalid-type': {
    title: 'Неподходящий формат файла',
    desc: `Загрузите файл в формате ${ACCEPTED_FILE_EXTENSIONS.split(',').join(', ')}.`
  }
};

export const getRejection = (errors:FileError[]):IFileRejection => {
  return rejections[errors[0].code];
};