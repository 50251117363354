import classNames from 'classnames';
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { IValidationErrors } from '%/validation/d';
import { Button } from '~/shared/components/controls/button';
import { Delimeter } from '~/shared/components/delimeter';
import { safeGetItemFromLS } from '~/shared/tools/safe-localstorage';
import { IFile } from '~/typings/file';

import { IFormProps } from './d';
import { getInputs, getValues } from './utils';

import styles from './form.module.styl';

let changeTimeout:number;

export const Form = <T, >({
  disabled, submitBtn = 'Отправить',
  onSubmit, onChange, validate, formId,
  className, children, showBtn = true, initial, restoreValues
}:IFormProps<T>) => {
  const [errors, setErrors] = useState({} as IValidationErrors<T>);
  const { state } = useLocation();
  const { navigation } = state || {};

  const formRef = useRef<HTMLFormElement>(null);
  const initialRef = useRef({
    ...initial,
    ...(navigation?.[navigation.length - 1]?.returnedParams || {}),
    ...(restoreValues ? (safeGetItemFromLS(`hr-doc:form:${formId}`) || {}) : {})
  });
  const filesRef = useRef<Record<string, IFile[]>>({});

  const [valuesState, setValues] = useState(initialRef.current);

  const onSubmitHandler = async (e:FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const values = getValues<T>(filesRef, formRef);

    let submitErrors:IValidationErrors<T>;
    if (validate) {
      submitErrors = validate(values);
      setErrors(submitErrors);
      if (Object.keys(submitErrors).length) {
        return submitErrors;
      }
    }
    //@ts-ignore
    submitErrors = await onSubmit(values);
    if (typeof submitErrors === 'object' && Object.keys(submitErrors)?.length) {
      setErrors(submitErrors);
      return;
    }
    window.localStorage.removeItem(`hr-doc:form:${formId}`);
  };

  const handleChange = () => {
    const values = getValues<T>(filesRef, formRef);
    setValues(values);
    changeTimeout && window.clearTimeout(changeTimeout);
    changeTimeout = window.setTimeout(() => {
      onChange?.(values);
    }, 500);
  };

  const saveFormValues = useCallback(() => {
    restoreValues && window.localStorage.setItem(`hr-doc:form:${formId}`, JSON.stringify(valuesState));
  }, [restoreValues]);
  useEffect(() => {
    window.addEventListener('beforeunload', saveFormValues);
    return () => {
      window.removeEventListener('beforeunload', saveFormValues);
    };
  }, [restoreValues]);

  return (
    <form
      ref={formRef}
      className={classNames(styles.root, className)}
      onChange={handleChange}
      onSubmit={onSubmitHandler}
      autoComplete="off"
    >
      {children({
        errors,
        filesRef,
        initial: initialRef.current,
        values: valuesState,
        inputs: getInputs(formRef),
        formChangeTrigger: handleChange,
      })}
      {showBtn && !disabled &&
        <>
          <Delimeter size="l"/>
          <Button
            type="submit"
            size="l"
            loadingId={formId}
            accent>
              { submitBtn }
          </Button>
        </>
      }
    </form>
  );
};