import classNames from 'classnames';
import inputmask from 'inputmask';
import { ChangeEvent, FocusEvent, useEffect, useRef, useState } from 'react';

import { IValidationError } from '%/validation/d';
import { generatePassword } from '~/shared/tools/generate-password';
import { trim } from '~/shared/tools/trim';

import { InputError } from '../error';
import { InputHint } from '../hint';
import { Label } from '../label';
import { cutMaskedValue } from './utils';

import eyeIcon from '@css-theme/icons/eye.svg';
import eyeCrossedIcon from '@css-theme/icons/eye-crossed.svg';
import keyIcon from '@css-theme/icons/key.svg';

import styles from './input.module.styl';

export type IInputProps = {
  name: string
  label?: string|React.ReactElement
  errors?: IValidationError
  defaultValue?: string
  type?: 'text'|'hidden'|'date'|'number'|'password'
  placeholder?: string
  mask?: string
  optionalMask?: string[]
  keepMaskedValue?: boolean
  focused?: boolean
  disabled?: boolean
  readonly?: boolean
  onChange?: (event:ChangeEvent<HTMLInputElement>, value:string) => void
  onClick?: (event:FocusEvent<HTMLInputElement>) => void
  password?: boolean
  generatePassOn?: boolean
  hint?: string
}

export const Input:React.FC<IInputProps> = ({
  name, label, errors, defaultValue = '', placeholder, type = 'text', disabled, readonly,
  mask, optionalMask = [], keepMaskedValue = true, password, generatePassOn,
  onChange, onClick, hint, ...props
}) => {
  const [focused, setFocused] = useState(props.focused);
  const [error, setError] = useState(errors);
  const [value, setValue] = useState(mask ? inputmask.format(defaultValue, { mask }) : defaultValue);
  const [clearValue, setClearValue] = useState(defaultValue || '');
  const maskRef = useRef(mask);

  const [passMode, setPassMode] = useState('pass');
  const showPass = () => setPassMode('text');
  const hidePass = () => setPassMode('pass');
  const handleGeneratePassword = () => {
    handleChange({ target: { value: generatePassword(12) } } as ChangeEvent<HTMLInputElement>);
  };

  const handleFocus = (e:FocusEvent<HTMLInputElement>) => {
    const ev = e;
    readonly && onClick?.(ev);
    setFocused(true);
  };

  const handleBlur = (e:FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    handleChange({ target: { value: e.target.value } } as ChangeEvent<HTMLInputElement>, true);
  };

  const handleChange = (event:ChangeEvent<HTMLInputElement>, trim?: boolean) => {
    setError(undefined);
    const cpEvent = event;
    const [clear, masked] = formatValue(event.target.value || '', trim);
    setValue(masked);
    setClearValue(clear);
    onChange?.(cpEvent, value);
  };

  const compareWithMask = (mask:string, val:string):boolean => {
    val = val.replace(/[\s()[\]+-]/g, '');
    mask.split('').forEach(char => {
      if (char === val[0] ||
        char === '9' && /[\d]/.test(val[0]) ||
        char === 'a' && /[a-zа-яё]/.test(val[0].toLowerCase()) ||
        char === '*' && /[a-zа-яё\d]/.test(val[0].toLowerCase())
      ) {
        val = val.slice(1);
      }
    });

    return !val.length;
  };

  const checkOptionalMask = (curValue:string) => {
    let trgValue = curValue;
    let newMask;
    if (curValue) {
      optionalMask.forEach((maskStr:string) => {
        const maskedVal = inputmask.format(curValue, { mask: maskStr });
        const unmaskedVal = inputmask.unmask(maskedVal, { mask: maskStr });
        if (curValue === unmaskedVal || compareWithMask(maskStr, curValue)) {
          newMask = maskStr;
          trgValue = unmaskedVal;
        }
      });
    }
    maskRef.current = newMask;
    return trgValue;
  };

  const formatValue = (val:string, needTrim?: boolean):string[] => {
    let clear = mask || needTrim ? trim(val) : val;
    let masked = mask || needTrim ? trim(val) : val;
    if (mask) {
      clear = inputmask.unmask(val, { mask: maskRef.current });
      masked = cutMaskedValue(clear, maskRef.current as string);
    } else if (optionalMask.length) {
      clear = checkOptionalMask(val);
      masked = maskRef.current ? cutMaskedValue(clear, maskRef.current) : clear;
    }
    return [clear, masked];
  };

  useEffect(() => { setError(errors) }, [errors]);
  useEffect(() => { setValue(defaultValue) }, [defaultValue]);

  return type === 'hidden' ? (
    <input name={name} value={value} type={type}/>
  ) : (
    <div className={styles.root}>
      { label && <Label>{ label }</Label> }
      <label className={classNames(styles.wrapper, {
        [styles.error]: error,
        [styles.focused]: focused,
        [styles.disabled]: disabled
      })}>
        { maskRef.current && <>
          <span className={styles.shadowMask}>{
            value ? inputmask.format(value, { mask: maskRef.current }) : placeholder || maskRef.current
          }</span>
        </> }
        <span className={maskRef.current ? styles.shadowValue : ''}>
          { maskRef.current ? value : ''}
          <input
            name={name}
            data-value={keepMaskedValue ? value : clearValue}
            value={clearValue}
            type={passMode === 'text' ? 'text' : type}
            placeholder={maskRef.current ? undefined : placeholder}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            readOnly={readonly}
            disabled={disabled}
            autoComplete="off"
          />
        </span>
        <span className={styles.rightAddons}>
          {password && <span onMouseEnter={showPass} onMouseLeave={hidePass}>
            {passMode === 'pass' && <img src={eyeCrossedIcon}/>}
            {passMode === 'text' && <img src={eyeIcon}/>}
          </span>}
          {generatePassOn && <span onClick={handleGeneratePassword}><img src={keyIcon}/></span>}
        </span>
      </label>
      <InputHint hint={hint}/>
      <InputError errors={error as string}/>
    </div>
  );
};