import { Suspense } from 'react';

import { Loader } from '~/shared/components/loader';

export const Loadable = (Component:React.FC) => ({ ...props }:any) => {
  return (
    <Suspense fallback={<Loader/>}>
      <Component {...props}/>
    </Suspense>
  );
};