import classNames from 'classnames';

import { Loader } from '~/shared/components/loader';
import { isPdf } from '~/shared/tools/file-helper';
import { IFile } from '~/typings/file';

import removeIcon from '@css-theme/icons/del.svg';
import removeIconMob from '@css-theme/icons/delete.svg';
import iconDoc from '@css-theme/icons/file.svg';

import styles from './file-preview.module.styl';

export type IFilePreviewProps = {
  file: IFile
  onRemoveClick?: (file:IFile) => void
  error?: boolean
  filesWithError?: string[]
}

export const FilePreview:React.FC<IFilePreviewProps> = ({
  onRemoveClick, file = {}, error, filesWithError
}) => {
  const onRemove = () => {
    onRemoveClick?.(file);
  };

  return (
    <div className={styles.root}>
      <div className={classNames(styles.preview, {
        [styles.error]: error || filesWithError?.some((fileWithError:string) => file.preview?.includes(fileWithError)),
        [styles.withStub]: isPdf(file) || !file?.preview,
        [styles.loading]: file.uploading
      })}>
        { isPdf(file) ?
          <object type="application/pdf" data={`${file.preview}#toolbar=0&navbar=0`}>
            <img src={iconDoc}/>
          </object> :
          <img src={file.preview || iconDoc}/>
        }
      </div>
      { onRemoveClick && <p className={styles.title}>{ file.name }</p> }
      { (!file.uploading && onRemoveClick || file.uploading) &&
        <div className={styles.remove} onClick={onRemove}>
          <img src={removeIcon}/>
          <img src={removeIconMob}/>
        </div>
      }
      { file.uploading &&
        <Loader className={styles.uploading}/>
      }
    </div>
  );
};