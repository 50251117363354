import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { UserRolesRu } from '%/data/user-roles';
import { IUser } from '%/entities/user';
import { isEmployer } from '%/utils/user';
import { getViewAbsoluteUrl } from '~/core/views';
import { CompanyLogo } from '~/shared/components/company-logo';
import { useUser } from '~/shared/hooks/use-user';
import { getUserNameInitials } from '~/shared/tools/get-name-initials';

import logoutIcon from '@css-theme/icons/logout.svg';

import styles from './page-layout.module.styl';

type IPageLayoutProps = {
  renderFooter?: boolean
  centeredContent?: boolean
  fs?: boolean
  children?: React.ReactNode
}

export const PageLayout:React.FC<IPageLayoutProps> = ({
  children,
  renderFooter = true,
  centeredContent, fs
}) => {
  const { user, logout } = useUser();
  const authed = user?.token;
  const company = user?.offer?.offerCompany || user?.workCompany;
  const showCompanyFooter = !authed && renderFooter && company;

  return (
    <div className={classNames(styles.root, { [styles.bgColored]: isEmployer(user) || !authed })}>
      <div className={styles.header}>
        <CompanyLogo url={company?.logo as string}/>
        {authed && user && user.lastName &&
          <div className={styles.links}>
            <Link to={getViewAbsoluteUrl('profile')} className={styles.profile}>
              {getUserNameInitials(user as IUser)} ({UserRolesRu[user.role]})
            </Link>
            <img src={logoutIcon} onClick={logout} className={styles.logout}/>
          </div>
        }
      </div>
      <div className={classNames(styles.content, {
        [styles.centered]: centeredContent,
        [styles.fs]: fs
      })}>
        { children }
      </div>
      {showCompanyFooter &&
        <div className={styles.footer}>
          <a href={`mailto:${company?.email}`}>{company?.email}</a>
          <p>©{company?.title}, {(new Date()).getFullYear()}</p>
        </div>
      }
    </div>
  );
};