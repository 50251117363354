import classNames from 'classnames';

import styles from './delimeter.module.styl';

type IDelimeterProps = {
  size?: 'xxs'|'xs'|'s'|'m'|'l'
  underline?: boolean
}

export const Delimeter:React.FC<IDelimeterProps> = ({ size = 'm', underline }) => (
  <div className={classNames(styles.root, styles[`size-${size}`], {
    [styles.underline]: underline
  })}/>
);