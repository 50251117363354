import { useEffect, useRef, useState } from 'react';

import { isEmployer } from '%/utils/user';
import { useApi } from '~/core/api';
import { InputDnd } from '~/shared/components/controls/input-dnd';
import { useUser } from '~/shared/hooks/use-user';
import { IFile } from '~/typings/file';

import styles from './company-logo.module.styl';

type ICompanyLogoProps = {
  editable?: boolean
  url?: string
}

export const CompanyLogo:React.FC<ICompanyLogoProps> = ({ editable = false, url }) => {
  const { user } = useUser();
  const { fetchApi } = useApi();
  const company = isEmployer(user) ? user?.offer?.offerCompany : user?.workCompany;
  const [logoUrl, setLogoUrl] = useState((company?.logo as string) || url);
  const filesRef = useRef({} as { logo: IFile[] });
  const handleLogoUpload = async () => {
    if (user?.workCompany?.id) {
      const resp = await fetchApi(`/api/companies/${user.workCompany.id}`, {
        method: 'put',
        json: JSON.stringify({ logo: filesRef.current.logo[0].preview }),
        notifyWhenFailed: true
      });
      if (!resp?.errorStatus) {
        setLogoUrl(resp.logo);
      }
    }
  };

  useEffect(() => {
    url && setLogoUrl(url);
  }, [url]);

  return (
    <div className={styles.root}>
      {logoUrl && <img src={logoUrl} className={styles.logo}/>}
      {editable &&
        <InputDnd
          name="logo"
          filesRef={filesRef}
          multiple={false}
          view={logoUrl ? 'link' : 'button'}
          uploadUrl={`/api/files/${company?.id}logo`}
          onChange={handleLogoUpload}
          buttonProps={{
            accent: true,
            bordered: true,
            text: 'Загрузить лого',
            size: 'xs'
          }}
          linkText="Загрузить другой логотип"
          className={logoUrl ? styles.hidable : ''}
        />
      }
    </div>
  );
};