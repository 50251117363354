import classNames from 'classnames';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import { useApi } from '~/core/api';
import { Button } from '~/shared/components/controls/button';
import { Delimeter } from '~/shared/components/delimeter';
import { FilePreview, FilePreviews } from '~/shared/components/file-preview';
import { FileStubPreview } from '~/shared/components/file-preview/item-stub';
import { Paragraph } from '~/shared/components/paragraph';
import { Popup } from '~/shared/components/popup';
import { IFile } from '~/typings/file';

import { InputError } from '../error';
import { ACCEPTED_FILE_EXTENSIONS, MAX_UPLOAD_FILE_SIZE } from './constants';
import { IFileRejection, IInputDndProps } from './d';
import { getRejection } from './helper';

import uploadIcon from '@css-theme/icons/upload.svg';
import fileIcon from '@css-theme/icons/upload-file.svg';

import styles from './input-dnd.module.styl';

const INITIAL_FILES:IFile[] = [];

export const InputDnd:React.FC<IInputDndProps> = ({
  name, initialFiles = INITIAL_FILES, errors, recognitionErrors = [], filesRef, filesWithError,
  multiple = true, globalDndEnabled = true, view = 'dnd', showPreviews = true,
  uploadUrl, onChange, buttonProps, linkText, className
}) => {
  const [globalEnabled, setGlobalEnabled] = useState(globalDndEnabled);
  const [globalVisible, setGlobalVisible] = useState(false);
  const { fetchApi } = useApi();

  const [hasFiles, setHasFiles] = useState(false);

  const [rejection, setRejection] = useState<IFileRejection|null>(null);
  const resetRejection = () => { setRejection(null) };
  const setFilesRef = (files:IFile[]) => { filesRef.current[name] = [...files] };

  const uploadFile = async (file:IFile) => {
    const formData = new FormData();
    formData.append('files', file.originalFile || '');
    const { files: uploadedFiles, message, errorStatus } = await fetchApi(uploadUrl, {
      signal: file.abortController?.signal,
      method: 'put',
      body: formData,
      notifyWhenFailed: true
    });
    if (errorStatus) {
      console.error('error by saving files', message);
      return;
    }
    URL.revokeObjectURL(file.preview || '');
    // @ts-ignore
    file = null;
    return uploadedFiles[0];
  };

  const updateFiles = useCallback((filesToAdd:IFile[], triggerChange = true, replaceState = false) => {
    const filtered = replaceState ? [...filesToAdd] :
      (multiple ?
        [
          ...(filesRef.current[name] || []).filter(file => filesToAdd.every(fileToAdd => fileToAdd.name !== file.name)),
          ...filesToAdd
        ] :
        [filesToAdd[0]]
      );
    setFilesRef(filtered);
    setHasFiles(!!filtered.length);
    triggerChange && onChange?.(filtered);
  }, []);

  const handleDrop = useCallback(async (acceptedFiles:File[], rejections:FileRejection[]) => {
    if (rejections?.length) {
      setRejection(getRejection(rejections[0].errors));
    }
    if (!acceptedFiles?.length) return;
    const transformedFiles:IFile[] = acceptedFiles.map(file => ({
      originalFile: file,
      name: file.name,
      preview: URL.createObjectURL(file),
      uploading: true,
      abortController: new AbortController()
    }));
    updateFiles(transformedFiles, false);
    setGlobalVisible(false);

    const uploadedFiles = await Promise.all(transformedFiles.map(uploadFile));
    // @ts-ignore
    acceptedFiles = null;
    updateFiles(uploadedFiles);
  }, []);

  const handleDragLeave = useCallback(() => setGlobalVisible(false), []);
  const handleDragEnter = useCallback(() => setGlobalVisible(true), []);
  const handleMouseDown = useCallback(() => setGlobalEnabled(false), []);
  const handleMouseUp = useCallback(() => setGlobalEnabled(true), []);
  const handleMouseLeave = useCallback(() => {
    setGlobalEnabled(true);
    setGlobalVisible(false);
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: handleDrop,
    onDragLeave: handleDragLeave,
    noClick: true,
    accept: { 'image/*': ACCEPTED_FILE_EXTENSIONS.split(',') },
    maxSize: +MAX_UPLOAD_FILE_SIZE * 1024 * 1024,
    multiple
  });

  const onRemoveHandler = useCallback((file:IFile) => {
    file.uploading && file.abortController?.abort();
    let filtered = filesRef.current[name].filter(el => el.name !== file.name);
    updateFiles(filtered, true, true);

    file.uploading && URL.revokeObjectURL(file.preview || '');
    if (typeof file.preview === 'string') fetchApi(`/api/${file.preview.split('/api/')[1]}`, { method: 'delete' });
  }, []);

  const handleDndClick = useCallback((e:MouseEvent) => {
    e.preventDefault();
    open();
  }, [open]);

  useEffect(() => {
    window.document.addEventListener('dragenter', handleDragEnter);
    window.document.addEventListener('mousedown', handleMouseDown);
    window.document.addEventListener('mouseup', handleMouseUp);
    window.document.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      window.document.removeEventListener('dragenter', handleDragEnter);
      window.document.removeEventListener('mousedown', handleMouseDown);
      window.document.removeEventListener('mouseup', handleMouseUp);
      window.document.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  useEffect(() => {
    const files = initialFiles.filter(file => file.name).map(file => ({ ...file, uploaded: true }));
    updateFiles(files, false, true);
  }, [initialFiles]);

  const fileErrors:string|string[]|undefined = recognitionErrors?.length ?
    [...(
      typeof errors === 'boolean' ?
        ['Загрузи документы'] :
        Array.isArray(errors) ?
          errors :
          errors ? [errors] : []),
      ...recognitionErrors
    ] :
    typeof errors === 'boolean' ?
      'Загрузи документы' : errors;

  return (
    <>
      <div className={classNames(styles.root, className)}>
        <div {...getRootProps({ className: globalVisible && globalEnabled ? styles.dragzone : '' })}>
          <input {...getInputProps({ name })}/>
        </div>
        <div className={classNames(styles[`view-${view}`], { [styles.error]: fileErrors?.length })}>
          { showPreviews && hasFiles && view !== 'link' &&
            <FilePreviews>
              { filesRef.current[name].map(file => (
                <FilePreview
                  key={file.name}
                  file={file}
                  onRemoveClick={onRemoveHandler}
                  filesWithError={filesWithError}
                />
              )) }
              { multiple && <FileStubPreview onClick={handleDndClick}/> }
            </FilePreviews>
          }

          { view === 'dnd' && !hasFiles &&
            <div className={styles.dropzone}>
              <img src={uploadIcon}/>
              <Delimeter size="s"/>
              <h6>Загрузи документы</h6>
              <Paragraph size="s">Перетащи файлы сюда или <a onClick={handleDndClick}>выбери на компьютере…</a></Paragraph>
            </div>
          }
          { view === 'dnd-mobile' && !hasFiles &&
            <>
            <div/>
            <div className={styles.dropzone}>
              <img src={fileIcon}/>
              <Delimeter size="xs"/>
              <Paragraph size="s" grey>Ни одного файла пока<br/>не загружено</Paragraph>
            </div>
            <Button {...buttonProps} onClick={handleDndClick}/>
            </>
          }
          { view === 'button' &&
            <Button {...buttonProps} onClick={handleDndClick}/>
          }
          { view === 'link' &&
            <a onClick={handleDndClick}>{linkText}</a>
          }
        </div>
      </div>
      {!!fileErrors?.length && <InputError errors={fileErrors}/>}
      { rejection &&
        <Popup onClose={resetRejection} closeIcon>
          <h2>{ rejection.title }</h2>
          <p>{ rejection.desc }</p>
          <Delimeter size="l"/>
          <Button onClick={resetRejection} accent>Понятно</Button>
        </Popup>
      }
    </>
  );
};