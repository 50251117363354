import classNames from 'classnames';

import styles from './form.module.styl';

type IFormRowProps = {
  part?: string
  align?: 'top'|'bottom'|'center'
  children?: React.ReactNode
}

export const FormRow:React.FC<IFormRowProps> = ({ children, part = '1-1', align = 'top' }) => {
  return (
    <div className={classNames(styles.row, styles[`part-${part}`], styles[`align-${align}`])}>
      { children }
    </div>
  );
};