import classNames from 'classnames';
import { ChangeEvent } from 'react';

import { IValidationError } from '%/validation/d';

import { InputError } from '../error';
import { Label } from '../label';

import checkIcon from '@css-theme/icons/check-white.svg';

import styles from './checkbox.module.styl';

type ICheckboxProps = {
  name: string
  label: string|React.ReactElement
  errors?: IValidationError
  value?: string
  defaultChecked?: boolean
  note?: string|React.ReactElement
  onChange?: (event:ChangeEvent<HTMLInputElement>, value:string) => void
  disabled?: boolean
  withLabelGap?: boolean
}

export const Checkbox:React.FC<ICheckboxProps> = ({
  name, label, errors, defaultChecked, value = '1', note, onChange, disabled, withLabelGap
}) => {
  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const cpEvent = event;
    onChange?.(cpEvent, event.target.value);
  };

  return (
    <div className={classNames(styles.root, {
      [styles.error]: errors,
      [styles.disabled]: disabled,
      [styles.withLabelGap]: withLabelGap
    })}>
      { withLabelGap && <Label>&nbsp;</Label> }
      <label>
        <input
          name={name}
          type="checkbox"
          value={value}
          defaultChecked={defaultChecked}
          onChange={handleChange}
          disabled={disabled}/>
        { label }
        <span className={styles.check}>
          <img src={checkIcon}/>
        </span>
      </label>
      {note && <span className={styles.note}>{ note }</span>}
      <InputError errors={errors || ''}/>
    </div>
  );
};