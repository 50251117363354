import classNames from 'classnames';
import QRCode from 'qrcode';
import { useCallback, useEffect, useState } from 'react';

import { Delimeter } from '~/shared/components/delimeter';
import { useMounted } from '~/shared/hooks/use-mounted';

import { Popup } from '../popup';

import icon from '~/shared/images/phone.png';

import styles from './qr-link.module.styl';

type IQRLinkProps = {
  text: string
  className?: string
}

export const QRLink:React.FC<IQRLinkProps> = ({ text, className }) => {
  const [opened, openPopup] = useState(false);
  const isMounted = useMounted();

  const [qr, setQr] = useState<string>('');
  const togglePopup = useCallback(() => { openPopup(!opened) }, [opened]);

  const generateQR = async () => {
    try {
      const qrDataUrl = await QRCode.toDataURL(text, { errorCorrectionLevel: 'H' });
      if (!isMounted()) return;
      setQr(qrDataUrl);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    generateQR();
  }, [text]);

  return (
    <>
      <div className={classNames(styles.root, className)}>
        <img src={icon}/>
        <p>Загрузи через телефон</p>
        <a onClick={togglePopup}>Как работает</a>
      </div>
      { opened &&
        <Popup onClose={togglePopup} closeIcon>
          <div className={styles.qrPopup}>
            <h2>Загрузка фото документов через телефон</h2>
            <p>Наведи камеру телефона на QR-код и перейди по ссылке на страницу для загрузки документов.</p>
            <Delimeter size="xs"/>
            <Delimeter size="xs"/>
            <img src={qr}/>
          </div>
        </Popup>
      }
    </>
  );
};