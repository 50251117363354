import classnames from 'classnames';

import { IDocument } from '%/entities/document';
import { useDictionary } from '~/shared/hooks/use-dictionary';

import okIcon from '@css-theme/icons/check.svg';
import errorIcon from '@css-theme/icons/error.svg';

import styles from './list.module.styl';

type IDocumentListProps = {
  documents: IDocument[]
  successField?: keyof IDocument
  clear?: boolean
  className?: string
};

export const DocumentList:React.FC<IDocumentListProps> = ({
  documents, successField = 'recognized', clear, className
}) => {
  const { documentsList } = useDictionary();
  const docs = documents.map((doc:IDocument) => ({
    ...doc,
    ...documentsList.find(document => document.docType === doc.docType)
  }));

  return (
    <ul className={classnames(styles.root, className)}>
      { docs.map(doc => (
        <li key={doc.docType} className={classnames(styles.item, clear ? '' : {
          [styles.itemComplete]: doc[successField],
          [styles.itemError]: doc.error
        })}>
          {!clear && <span className={styles.itemChecked}><img src={okIcon}/></span>}
          {!clear && <span className={styles.itemWrong}><img src={errorIcon}/></span>}
          { doc.title }
        </li>
      )) }
    </ul>
  );
};