import classNames from 'classnames';
import { ButtonHTMLAttributes, useEffect, useState } from 'react';

import { useNotification } from '~/shared/components/notification';

import loadingIcon from '@css-theme/icons/timer.svg';
import loadingIconColored from '@css-theme/icons/timer-colored.svg';

import styles from './button.module.styl';

export type IButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  text?: string
  accent?: boolean
  bordered?: boolean
  clear?: boolean
  loading?: boolean
  loadingId?: string
  noMinW?: boolean
  textL?: boolean
  size?: 'xs'|'s'|'m'|'l'
  children?: React.ReactNode
}

export const Button:React.FC<IButtonProps> = ({
  text, children, className, accent, bordered, type = 'button', size = 'm',
  clear, loading, disabled, loadingId, noMinW, textL, ...props
}) => {
  const { sendingIds } = useNotification();
  const [isLoading, setLoading] = useState(loading || loadingId && sendingIds.includes(loadingId));

  useEffect(() => {
    setLoading(loading || loadingId && sendingIds.includes(loadingId));
  }, [loading, loadingId, sendingIds]);

  return (
    <button {...props} disabled={disabled || loading} type={type} className={classNames(
      styles.root,
      className,
      styles[`size-${size}`], {
      [styles.accent]: accent,
      [styles.bordered]: bordered,
      [styles.loading]: isLoading,
      [styles.clear]: clear,
      [styles.textL]: textL,
      [styles.noMinW]: noMinW
    })}>
      { isLoading && !accent && <img className={styles.icon} src={loadingIconColored}/>}
      { isLoading && accent && <img className={styles.icon} src={loadingIcon}/>}
      { children || text }
    </button>
  );
};