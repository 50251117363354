import classNames from 'classnames';

import styles from './paragraph.module.styl';

type IParagraphProps = {
  size?: 'xs'|'s'|'m'|'l'|'xl'|'2xl'|'3xl'
  error?: boolean
  className?: string
  grey?: boolean
  children?: React.ReactNode
}

export const Paragraph:React.FC<IParagraphProps> = ({ size = 'm', error, grey, className, children }) => {
  return (
    <p className={classNames(className, styles[`size-${size}`], {
      [styles.error]: error,
      [styles.grey]: grey
    })}>{ children }</p>
  );
};