import classNames from 'classnames';
import { Fragment } from 'react';

import { IValidationError } from '%/validation/d';
import { Delimeter } from '~/shared/components/delimeter';
import { Paragraph } from '~/shared/components/paragraph';

import styles from './controls.module.styl';

export const InputError:React.FC<{ errors:IValidationError, className?:string }> = ({ errors, className }) =>
<>
  { errors && <Delimeter size="xs"/> }
  { errors ?
    (typeof errors === 'string' ?
      <Paragraph className={classNames(className, styles.error)} size="xs" error>{ errors }</Paragraph> :
      typeof errors === 'boolean' ?
        <Paragraph className={classNames(className, styles.error)} size="xs" error>Поле обязательно</Paragraph> :
        errors.map((err:string, i:number) => (<Fragment key={i}>
          <Paragraph className={classNames(className, styles.errorItem)} size="xs" error>{ err }</Paragraph>
          <Delimeter size="xs"/>
        </Fragment>))
    ) :
    null
  }
</>;