import { useEffect } from 'react';
import { useMatch, useNavigate, useRoutes } from 'react-router-dom';

import { OfferStatuses } from '%/data/offer';
import { generatePath, getViewAbsoluteUrl, views } from '~/core/views';
import { Loader } from '~/shared/components/loader';
import { PageLayout } from '~/shared/components/page-layout';
import { useOfferActions } from '~/shared/hooks/use-offer-actions';
import { useUser } from '~/shared/hooks/use-user';

import {
  EmployerComplete,
  EmployerUpload
} from '../employer';

const Employer:React.FC = () => {
  const navigate = useNavigate();
  const urlParams:Record<string, string> = {
    id: '',
    ...useMatch(getViewAbsoluteUrl('employer', 'offer'))?.params,
    ...useMatch(getViewAbsoluteUrl('employer', 'complete'))?.params,
  };
  const { user, updateUser } = useUser();
  const { getOffer, getOffers } = useOfferActions();
  const routes = useRoutes([
    { path: views.employer.complete, element: <EmployerComplete/> },
    { path: views.employer.offer, element: <EmployerUpload/> }
  ]);

  const loadOffer = async (id:string) => {
    const offer = await getOffer(id);

    if (offer.errorStatus) return;
    updateUser?.({ offers: [offer], offer }, false);
  };

  const loadOffers = async () => {
    const offers = await getOffers();

    if (offers.errorStatus) return;
    updateUser?.({ offers, offer: offers.reverse()[0] });
  };

  useEffect(() => {
    if (user?.offer) {
      navigate(generatePath(
        getViewAbsoluteUrl('employer', user.offer.status === 'Completed' ? 'complete' : 'offer'),
        { id: user.offer.id }
      ), { replace: true });
    }
  }, [user]);

  useEffect(() => {
    user?.token && loadOffers();
    !user?.token && urlParams?.id && loadOffer(urlParams.id);
  }, []);

  return (
    <PageLayout renderFooter={false} centeredContent={user?.offer?.status === OfferStatuses.Completed}>
      { user?.offer && user?.offer.id === urlParams?.id ? routes : <Loader/> }
    </PageLayout>
  );
};

export default Employer;