import classNames from 'classnames';

import plusIcon from '@css-theme/icons/plus.svg';

import styles from './file-preview.module.styl';

export type IFileStubProps = {
  onClick: (params:any) => void
}

export const FileStubPreview:React.FC<IFileStubProps> = ({ onClick }) => {
  return (
    <div className={styles.root}>
      <div className={classNames(styles.preview, styles.previewStub)} onClick={onClick}>
        <img src={plusIcon}/>
      </div>
    </div>
  );
};