import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { OfferStatuses } from '%/data/offer';
import { IDocument } from '%/entities/document';
import { IError } from '%/entities/error';
import { IOffer, IOfferData } from '%/entities/offer';
import { deflatObject } from '%/utils/flat-object';
import { offerFillValidate } from '%/validation/offer';
import { initEventSourceApi } from '~/core/api-sse';
import { generatePath, getViewAbsoluteUrl } from '~/core/views';
import { Button } from '~/shared/components/controls/button';
import { Checkbox } from '~/shared/components/controls/checkbox';
import { Form } from '~/shared/components/controls/form';
import { IFormRenderProps } from '~/shared/components/controls/form/d';
import { FormRow } from '~/shared/components/controls/form/form-row';
import { Input } from '~/shared/components/controls/input';
import { InputDnd } from '~/shared/components/controls/input-dnd';
import { Delimeter } from '~/shared/components/delimeter';
import { DocumentList } from '~/shared/components/documents/list';
import { FilePreview, FilePreviews } from '~/shared/components/file-preview';
import { Loader } from '~/shared/components/loader';
import { useNotification } from '~/shared/components/notification';
import { Paragraph } from '~/shared/components/paragraph';
import { QRLink } from '~/shared/components/qr-link';
import { useDictionary } from '~/shared/hooks/use-dictionary';
import { useOfferActions } from '~/shared/hooks/use-offer-actions';
import { useUser } from '~/shared/hooks/use-user';
import { WithRecognitionActionsProps, withRecognizeActions } from '~/shared/tools/recognition';
import { IFile } from '~/typings/file';

import styles from './upload.module.styl';

const FORM_ID = 'createRecognitionTaskProcess';

const EmployerUpload:React.FC<WithRecognitionActionsProps> = (
  { startRecognition, cancelRecognition, pollTaskResult }
) => {
  const navigate = useNavigate();
  const { createOffer, updateOffer } = useOfferActions();
  const { addSendingId, removeSendingId } = useNotification();
  const [recognitionErrors, setRecognitionErrors] = useState<string[]>([]);
  const [incorrectFiles, setIncorrectFiles] = useState<string[]>([]);
  const [pending, setTaskPending] = useState(false);
  const sseApiRef = useRef<EventSource | null>(null);

  const { user, updateUser } = useUser();
  const isTemplate = user?.offer?.template;
  const fromTemplate = user?.offer?.templateId;

  const { documentsList } = useDictionary();

  const saveOffer = useCallback(async (offer:IOffer, clb?:() => void) => {
    updateUser?.({ offer });
    if (isTemplate) return;

    const resp = await updateOffer(offer);
    resp?.id && clb?.();
  }, []);

  const checkTaskResult = (offer:IOffer) => {
    setTaskPending(true);
    pollTaskResult(offer.id)
      .then(offer => {
        updateUser?.({ offer });
        setTaskPending(false);

        if (offer.recognitionErrors || offer.recognitionErrorFiles) {
          setRecognitionErrors(offer.recognitionErrors || []);
          setIncorrectFiles(offer.recognitionErrorFiles || []);
        }
        if (offer.status === OfferStatuses.Completed) {
          navigate('./complete', { replace: true });
        }
      })
      .catch(reason => {
        console.error('recognition task failed with reason:' + JSON.stringify(reason));
      });
  };

  const onSubmit = useCallback(async (values:IOfferData) => {
    let offerFromTemplate:IOffer|IError|null = null;
    if (isTemplate) {
      const params:Partial<IOffer> = {
        type: user?.offer?.type,
        recognizePreset: user?.offer?.recognizePreset,
        templateId: user?.offer?.id,
        companyId: user?.offer?.companyId,
        data: {
          ...values,
          documents: user?.offer?.data?.documents
        }
      };
      addSendingId(FORM_ID);
      offerFromTemplate = await createOffer(params, false);
      removeSendingId(FORM_ID);
      if ((offerFromTemplate as IError).errors) {
        return deflatObject((offerFromTemplate as IError).errors);
      }
    }

    addSendingId(FORM_ID);
    const resp = await startRecognition((isTemplate ? (offerFromTemplate as IOffer).id : user?.offer?.id) || '');
    removeSendingId(FORM_ID);

    if ((resp as IOffer).data?.recognitionTaskId) {
      checkTaskResult(resp as IOffer);
    }
    if ((resp as IError).errorText) {
      console.error('create recognition task failed with reason: ' +
        JSON.stringify(resp)
      );
    }
    if ((offerFromTemplate as IOffer)?.id) {
      saveOffer(offerFromTemplate as IOffer);
      navigate(generatePath(
        getViewAbsoluteUrl('employer', 'offer'),
        { id: (offerFromTemplate as IOffer).id }
      ), { replace: true });
    }
  }, [user]);

  const onChange = useCallback((values:IOfferData) => {
    saveOffer({ ...user?.offer, data: { ...user?.offer?.data, ...values } } as IOffer);
  }, [user]);

  const onCancel = useCallback(async () => {
    const resp = await cancelRecognition(user?.offer?.id || '');
    if ((resp as IError).errorText) {
      return;
    }
    updateUser?.({ offer: resp as IOffer });
    setTaskPending(false);
  }, [user]);

  useEffect(() => {
    if (user && user.offer && user.offer.data &&
      user.offer.data.recognitionTaskId && documentsList?.length &&
      user.offer.status !== OfferStatuses.Closed && user.offer.status !== OfferStatuses.Completed
    ) {
      checkTaskResult(user.offer);
    }
  }, [documentsList]);

  useEffect(() => {
    sseApiRef.current = initEventSourceApi(user?.offer?.id || '');
    const eventHandler = (event: MessageEvent) => {
      if (event instanceof MessageEvent) {
        const data = JSON.parse(event?.data);
        if (data?.offerId === user?.offer?.id) {
          onChange({ files: data.files });
        }
      }
    };
    sseApiRef.current?.addEventListener('filesupdated', eventHandler);
    return () => {
      sseApiRef.current?.removeEventListener('filesupdated', eventHandler);
    };
  }, [user?.offer?.id]);

  useEffect(() => () => {
    sseApiRef.current?.close();
  }, []);

  return user?.offer ? (
    <div className={styles.wrapper}>
      {isTemplate || fromTemplate ?
        <div className={styles.welcome}>
          <h1>{ user.offer.title }</h1>
          <p>Чтобы отправить свою кандидатуру на рассмотрение, укажите контактную информацию,
            дайте свое согласие с условиями и загрузите указанные документы.</p>
        </div> :
        <div className={styles.welcome}>
          <h1>{ user.offer.data?.firstName }, рады видеть тебя в нашей команде!</h1>
          <p>Пришло время подкинуть работы отделу кадров.
            Чтобы оформить тебя на работу, нам потребуется согласие
            с условиями и документы.</p>
        </div>
      }
      <div className={styles.formWrapper}>
        { pending ?
          <div className={styles.waiting}>
            <Loader/>
            <Delimeter size="m"/>
            <Delimeter size="xs"/>
            <h2>Проверяем документы</h2>
            <Paragraph>Это займет около минуты</Paragraph>
            <Delimeter size="m"/>
            <FilePreviews>
              { user?.offer?.data?.files?.map((file:IFile, i:number) =>
                <FilePreview key={`${i}_${file.name}`} file={file}/>
              ) }
            </FilePreviews>
            <Delimeter size="m"/>
            <Delimeter size="m"/>
            <Button onClick={onCancel} bordered size="xs">Отменить</Button>
          </div> :
          <Form<IOfferData>
            onSubmit={onSubmit}
            validate={offerFillValidate}
            onChange={onChange}
            initial={user?.offer?.data as IOfferData}
            formId={FORM_ID}
          >
            { ({ errors, filesRef, initial, formChangeTrigger }:IFormRenderProps<IOfferData>) => (
              <>
                <h4>1. Согласия</h4>
                <Checkbox
                  label={<>Даю согласие на <a href="/files/%D0%A1%D0%9E%D0%9F%D0%94.docx" download>обработку персональных данных</a></>}
                  name="personalAgreement"
                  errors={errors.personalAgreement}
                  defaultChecked={initial.personalAgreement}
                  disabled={!!fromTemplate}
                />
                <Delimeter/>

                {isTemplate || fromTemplate ? <>
                  <h4>2. Контактные данные</h4>
                  <Delimeter size="xs"/>
                  <Input type="hidden" name="templateId" defaultValue={user.offer?.id}/>
                  <FormRow>
                    <Input
                      label="Фамилия"
                      name="lastName"
                      errors={errors.lastName}
                      defaultValue={initial.lastName}
                      disabled={!!fromTemplate}/>
                    <Input
                      label="Имя"
                      name="firstName"
                      errors={errors.firstName}
                      defaultValue={initial.firstName}
                      disabled={!!fromTemplate}/>
                    <Input
                      label="Отчество"
                      name="middleName"
                      defaultValue={initial.middleName}
                      disabled={!!fromTemplate}/>
                  </FormRow>
                  <Delimeter size="m"/>
                  <FormRow>
                    <Input
                      label="Телефон"
                      name="phone"
                      errors={errors.phone}
                      defaultValue={initial.phone}
                      mask="+7 999 999-99-99"
                      disabled={!!fromTemplate}/>
                    <Input
                      label="Email"
                      name="email"
                      errors={errors.email}
                      defaultValue={initial.email}
                      disabled={!!fromTemplate}/>
                  </FormRow>
                  <Delimeter size="l"/>
                </> : null }

                <h4>{isTemplate || fromTemplate ? 3 : 2}. Документы</h4>
                <DocumentList documents={user?.offer?.data?.documents as IDocument[]} successField="recognized"/>
                <Delimeter size="xs"/>
                <Delimeter size="xs"/>
                <div className={styles.filesUpload}>
                  <InputDnd
                    name="files"
                    initialFiles={initial.files}
                    filesRef={filesRef}
                    errors={errors.files}
                    recognitionErrors={recognitionErrors}
                    filesWithError={incorrectFiles}
                    uploadUrl={`/api/files/${user?.offer?.id}`}
                    onChange={formChangeTrigger}
                  />
                  {isTemplate ? null :
                    <QRLink text={`${window.location.origin}/upload/${user?.offer?.id}`} className={styles.qrLink}/>
                  }
                </div>
              </>
            )}
          </Form>
        }
      </div>
    </div>
  ) : null;
};

export default withRecognizeActions(EmployerUpload);