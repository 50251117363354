import { RefObject } from 'react';

import { IFile } from '~/typings/file';

const setValue = (trg:Record<string, any>, paths:string[], val:any):void => {
  if (!trg || !paths?.length) return;

  const path = paths.shift() as string;
  if (!paths.length) {
    trg[path] = val;
    return;
  }
  trg[path] = trg[path] || {};
  setValue(trg[path], paths, val);
};

export const getInputs = (formRef?:RefObject<HTMLFormElement>):HTMLInputElement[] => Array.prototype.filter.apply(
  (formRef?.current || document.forms[0])?.elements || [],
  [(el:HTMLInputElement) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(el.nodeName) && !el.name.includes('synthetic')]
);

export const getValue = (
  name:string,
  formRef?:RefObject<HTMLFormElement>
) => {
  //@ts-ignore
  const input = (formRef?.current || document.forms[0])?.elements[name];
  const isCheckbox = input?.type === 'checkbox';
  const isTextInput = ['text', 'textarea', 'password'].includes(input?.type);
  const isCheckboxGroup = !input?.nodeName && input?.length;

  let value = isCheckboxGroup ?
    [] :
    isTextInput ?
      (input.dataset?.value || input.value) :
      isCheckbox ?
        (input.checked && input.value || false) :
        null;
  value = typeof value === 'string' && !value ? null : value;

  if (isCheckboxGroup) {
    Array.prototype.forEach.apply(
      //@ts-ignore
      input,
      [(el:HTMLInputElement) => el.checked && value.push(el.value)]
    );
  }

  return name.includes('[]') ? Array.isArray(value) ? value : [value] : value;
};

export const getValues = <T, >(
  filesRef?:RefObject<Record<string, IFile[]>>,
  formRef?:RefObject<HTMLFormElement>
):T => {
  const inputs = getInputs(formRef);

  return inputs.reduce((res:T, input:HTMLInputElement) => {
    let prevValue:T = res;
    const nameParts = input.name.replace('[]', '').split('.');

    nameParts.forEach((key:string) => {
      //@ts-ignore
      prevValue = prevValue?.[key];
    });
    let curValue = input.type === 'checkbox' ?
      input.name.includes('[]') ?
        input.checked ?
        //@ts-ignore
          (prevValue || []).concat(input.value) :
          prevValue :
        input.checked :
      input.type === 'file' ?
        filesRef?.current?.[input.name] :
        (input.dataset?.value || input.value);
    curValue = typeof curValue === 'string' && !curValue ? null : curValue;

    setValue(res, nameParts, curValue);
    return res;
  }, {} as T) as T;
};

export const changeFormValue = (name:string, value:any) => {
  //@ts-ignore
  const trgEl = document.forms[0].elements[name];
  // const isCheckbox = trgEl?.type === 'checkbox';
  // const isTextInput = ['text', 'textarea'].includes(trgEl?.type);
  const isCheckboxGroup = !trgEl?.nodeName && trgEl?.length;

  if (isCheckboxGroup) {
    Array.prototype.forEach.apply(
      //@ts-ignore
      trgEl,
      [(el:HTMLInputElement) => el.checked = Array.isArray(value) ? value.includes(el.value) : el.value === value]
    );
  }
};